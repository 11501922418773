import React, {useState, useEffect} from 'react';
import {Col, Container, Row} from "reactstrap";
import EventItem from "./item";
import {Slider} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {uniqueMinMaxPrice} from "../../services";
import {priceValue} from "../../actions/filter";
import PageTitle from "../../widgets/PageTitle";
import API from "../../network";
import moment from "moment";

const EventPii = ({navigation}) => {
    const dispatch = useDispatch();

    const prices = useSelector(state => uniqueMinMaxPrice(state.data.products));
    const setPriceValue = (value) => dispatch(priceValue(value));

    const bidang = useSelector(state => state.pii_event.bidang);
    const daerah = useSelector(state => state.pii_event.daerah);

    const piiEvents = useSelector(state => state.pii_event.piiEvents);
    const setPiiEvents = (data) => dispatch({type: 'SET_PII_EVENTS', payload: data});

    const [priceplace, setPriceplace] = useState([prices.min, prices.max]);

    const [eventPiiFilter, setEventPiiFilter] = useState({sort_date: 'desc'});

    useEffect(() => {
        getEventPiis();
    }, [eventPiiFilter]);

    const getEventPiis = async () => {
        try{
            let data = await API.event.get(eventPiiFilter);
            console.log(data);
            setPiiEvents(data.events);
        }catch (e) {
            console.log(e)
        }
    };

    const toolformatter = value => {
        var maximumval = prices.max / 5;
        if (value === 0) {
            value = "0";
        }
        else if (value > 0 && value <= 20) {
            value = value * (maximumval * 1) / 20;
        }
        else if (value > 20 && value <= 40) {
            value = value * (maximumval * 2) / 40;
        }
        else if (value > 40 && value <= 60) {
            value = value * (maximumval * 3) / 60;
        }
        else if (value > 60 && value <= 80) {
            value = value * (maximumval * 4) / 80;
        }
        else if (value > 80 && value <= 100) {
            value = value * (maximumval * 5) / 100;
        }
        return convertValue(value);
    };

    const onChangePricePlace = values => {
        var maximumval = prices.max / 5;

        console.log(prices);


        var value = {
            min: values['0'],
            max: values['1']
        }
        if (value.min == 0) {
            value.min = 0;
        }
        else if (value.min > 0 && value.min <= 20) {
            value.min = parseInt(fncl(value.min * (maximumval * 1) / 20));
        }
        else if (value.min > 20 && value.min <= 40) {
            value.min = parseInt(fncl(value.min * (maximumval * 2) / 40));
        }
        else if (value.min > 40 && value.min <= 60) {
            value.min = parseInt(fncl(value.min * (maximumval * 3) / 60));
        }
        else if (value.min > 60 && value.min <= 80) {
            value.min = parseInt(fncl(value.min * (maximumval * 4) / 80));
        }
        else if (value.min > 80 && value.min <= 100) {
            value.min = parseInt(fncl(value.min * (maximumval * 5) / 100));
        }
        else {
            value.min = false;
        }

        if (value.max === 0) {
            value.max = 0;
        }
        else if (value.max > 0 && value.max <= 20) {
            value.max = parseInt(fncl(value.max * (maximumval * 1) / 20));
        }
        else if (value.max > 20 && value.max <= 40) {
            value.max = parseInt(fncl(value.max * (maximumval * 2) / 40));
        }
        else if (value.max > 40 && value.max <= 60) {
            value.max = parseInt(fncl(value.max * (maximumval * 3) / 60));
        }
        else if (value.max > 60 && value.max <= 80) {
            value.max = parseInt(fncl(value.max * (maximumval * 4) / 80));
        }
        else if (value.max > 80 && value.max <= 100) {
            value.max = parseInt(fncl(value.max * (maximumval * 5) / 100));
        }
        else {
            value.max = false;
        }
        setPriceplace(values);
        setPriceValue({ value })
        
    };

    const onAfterChangePricePlace = values => {
        var value = {
            min: values['0'],
            max: values['1']
        }

        setEventPiiFilter({...eventPiiFilter, price_from: prices.max * value.min / 100, price_to: prices.max * value.max / 100});
    }

    const convertValue = (labelValue) => {
        return labelValue.toLocaleString("en", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
    };

    const fncl = (value) => {
        return Number.parseFloat(value).toFixed(0);
    }

    const clearprice = (pricesval) => {
        var value = {
            min: pricesval.min,
            max: pricesval.max
        }
        setPriceplace([prices.min, prices.max]);
        setPriceValue({ value });
        setEventPiiFilter({...eventPiiFilter, price_from: value.min, price_to: value.max})
    }

    let maxdivide = prices.max / 5;
    const marks = {
        0: 0,
        20: ((maxdivide * 1).toLocaleString(navigator.language, { minimumFractionDigits: 0 })),
        40: ((maxdivide * 2).toLocaleString(navigator.language, { minimumFractionDigits: 0 })),
        60: ((maxdivide * 3).toLocaleString(navigator.language, { minimumFractionDigits: 0 })),
        80: ((maxdivide * 4).toLocaleString(navigator.language, { minimumFractionDigits: 0 })),
        100: prices.max.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
    };

    return (
        <div className="site-content">
            <div className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                <Container>
                    <Row className="intro-title align-items-center intro-section-center">
                        <PageTitle title="Event"/>
                    </Row>
                </Container>
            </div>
            <div className="content-wrapper">
                <Container className={'my-3'}>
                    <Row className="latest-post-wrapper latest-post-type-carousel latest-post-style-2 latest-post-without-intro">
                        <Col sm={12}>
                            <Row className={'mb-3'}>
                                <Col sm={3} className={'my-auto'}>
                                    <select className={'form-control'} name="daerah" onChange={e => setEventPiiFilter({...eventPiiFilter, location_id: e.target.value})}>
                                        <option disabled selected>Pilih daerah...</option>
                                        {
                                            daerah.map(v => (
                                                <option value={v.id}>{v.name}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                                <Col sm={3} className={'my-auto'}>
                                    <select className={'form-control'} name="topik" id="" onChange={e => setEventPiiFilter({...eventPiiFilter, bidang_id: e.target.value})}>
                                        <option disabled selected>Pilih topik...</option>
                                        {
                                            bidang.map(v => (
                                                <option value={v.value}>{v.label}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                                <Col sm={3} className={'my-auto'}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="widget-title">Harga</h4>
                                        <p><a className="price-clear-filter" onClick={() => clearprice(prices)}>Clear</a></p>
                                    </div>
                                    <div className="section-title my-auto">
                                        <div className="shop-filter-wrapper">
                                            <div className="price_slider_wrapper">
                                                <Slider
                                                    range
                                                    step={1}
                                                    min={0}
                                                    max={100}
                                                    tipFormatter={toolformatter}
                                                    value={priceplace}
                                                    onChange={onChangePricePlace}
                                                    onAfterChange={onAfterChangePricePlace}
                                                    marks={marks}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={3} className={'my-auto mb-3'}>
                                    <p>Pilih tanggal : </p>
                                    <input type="date" className={'form-control mb-3'} onChange={e => setEventPiiFilter({...eventPiiFilter, date_from: e.target.value})} value={eventPiiFilter.date_from} />
                                    <input type="date" className={'form-control'} onChange={e => setEventPiiFilter({...eventPiiFilter, date_to: e.target.value})} value={eventPiiFilter.date_to} />
                                </Col>
                                <Col sm={3} className={'my-auto mb-3'}>
                                    <p>Urutkan berdasarkan waktu mulai :</p>
                                    <select className={'form-control'} name="sort_date" onChange={e => setEventPiiFilter({...eventPiiFilter, sort_date: e.target.value})} value={eventPiiFilter.sort_date} >
                                        <option value='desc'>Terbaru</option>
                                        <option value='asc'>Terlama</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    piiEvents.map(v => (
                                        <Col sm={3} className={'my-4 entry-content'}>
                                            <EventItem {...v} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    );
};

export default EventPii;
