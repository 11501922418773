import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

export default (state = {
    bidang: [],
    daerah: [],
    piiEvents: [],
    banners: [],
}, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'SET_PII_EVENTS':
            return {
                ...state,
                piiEvents: payload.map(v => {
                    v.started_at = moment(v.started_at);
                    v.finished_at = moment(v.finished_at);
                    v.date = moment(v.date);

                    return v
                })
            };
        case 'SET_BANNERS':
            return {
              ...state,
              banners: payload
            };
        case 'SET_BIDANG':
            return {
                ...state,
                bidang: payload.map(v => (
                    {
                        "value": v.id,
                        "label": v.name
                    }
                ))
            };
        case 'SET_DAERAH':
            return {
                ...state,
                daerah: payload
            };
        default:
            return {
                ...state
            };
    }
};
