/**
 *  Header Main
 */
import classnames from 'classnames';
import React, { Fragment } from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown
} from 'reactstrap';
import logo from '../../assets/images/logo.png';
import navLinks from '../../NavLinks.js';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import API from "../../network";
import swal from 'sweetalert2';
import {swalLoading} from "../../helpers";

class Header extends React.Component {
    constructor(props) {

        super(props);
        this.ReadCartItems = this.ReadCartItems.bind(this);
        this.ReadWishListItems = this.ReadWishListItems.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = {
            timeout: false,
            modal: false,
            activeTab: '1',
            isOpen: false,
            collapsed: true,
            CartHide:true,
            classset:'',
            emailLogin: '',
            passwordLogin: '',
            bidang_ids: [],
            name: '',
            email: '',
            password: '',
            confirm_password: '',
            photo: '',
            phone: '',
            gender: '',
            birth_date: '',
        }
        var removeFromCart, removeFromWishList;
        this.toggle = this.toggle.bind(this);
        this.logintoggle = this.logintoggle.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.ShowCart = this.ShowCart.bind(this);
    }

    handleChangePhoto = (e, field) => {
        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        this.setState({photo: e.target.files[0] });
        reader.onload = () => {
            this.setState({photo_preview: reader.result });
        };
    };

    handleRegister = async (e) => {
        e.preventDefault();

        let validations = [
            {field: 'bidang_ids', attribute: 'Bidang'},
            {field: 'name', attribute: 'Nama'},
            {field: 'email', attribute: 'E-mail'},
            {field: 'password', attribute: 'Kata sandi'},
            {field: 'confirm_password', attribute: 'Konfirmasi kata sandi'},
            {field: 'photo', attribute: 'Foto'},
            {field: 'phone', attribute: 'Nomor HP'},
            {field: 'gender', attribute: 'Jenis Kelamin'},
            {field: 'birth_date', attribute: 'Tanggal lahir'},
        ];

        let errors = [];

        validations.map(v =>{
            console.log(v.field);
           if(this.state[v.field].length < 1)
               errors.push(`${v.attribute} harus diisi`);
        });

        if(this.state.password !== this.state.confirm_password){
            errors.push('Konfirmasi kata sandi salah');
            return
        }

        let error_str = errors.join('<br>');

        if(errors.length > 0){
            swal.fire('Ups...', error_str, 'error');
            return
        }

        swalLoading();

        try{
            let res = await API.user.register(this.state);
            this.props.login(res);
            window.location.href = '/';
        }catch (e) {
            console.log(e);
        }

    };

    handleLogin = async (e) => {
        e.preventDefault();

        swalLoading();

        try{
            let res = await API.user.login({
                email: this.state.emailLogin,
                password: this.state.passwordLogin,
            });
            this.props.login(res);
            window.location.href = '/';
        } catch (e) {
            swal.fire('Email atau password salah', '', 'error');
        }
    }


    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleNavbar() {
        this.setState({
          collapsed: !this.state.collapsed
        });
      }

    logintoggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    ReadCartItems() {
        return JSON.parse(localStorage.getItem("LocalCartItems"));
    }
    removeFromCart = (Index) => {
        var UpdatedCart = JSON.parse(localStorage.getItem("LocalCartItems"));
        UpdatedCart = UpdatedCart.slice(0, Index).concat(UpdatedCart.slice(Index + 1, UpdatedCart.length));
        localStorage.removeItem("LocalCartItems");
        localStorage.setItem("LocalCartItems", JSON.stringify(UpdatedCart));
    }

    ReadWishListItems() {
        return JSON.parse(localStorage.getItem("LocalWishListItems"));
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

      componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
      }
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      }

      handleScroll(event) {
        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;

        if(scrollTop > 100)
        {
            document.getElementById("site-header").setAttribute("class","site-header header-style-menu-center is-sticky");
        } else {
            document.getElementById("site-header").setAttribute("class","site-header header-style-menu-center");
        }
      }

      ShowCart() {
          if(this.state.CartHide == true)
          {
            var elm = document.getElementById("DivCartContent");
            if(elm != null)
            {
            document.getElementById("DivCartContent").setAttribute("style","display:block");
            this.state.CartHide = false;
            }
          }
      }

      HideCart()
      {
           var elm = document.getElementById("DivCartContent");
           if(elm != null)
           {
           document.getElementById("DivCartContent").setAttribute("style","display:none");
           this.state.CartHide = true;
           }
      }

      closeNavbar() {
            if (this.state.collapsed !== true) {
            this.toggleNavbar();
            }
        }
    onClickClassAdd(pages){
        if(this.state.classset != pages)
        {
                this.setState({
                    ...this.state,
                    classset:pages
                })
        }
        else
        {
            if(Object.keys(this.state.classset).length == 0){
                this.setState({
                    ...this.state,
                    classset:pages
                })
            }
            else
            {
                this.setState({
                    ...this.state,
                    classset:''
                })
            }
        }

    }


    OpenSubmenuOpen(id) {
        var elm = document.getElementById(id);
        if(elm != null)
        {
            document.getElementById(id).setAttribute("class","dropdown-menu dropdown-menu-right show")
        }
    }

    OpenSubmenuClose(id) {
        var elm = document.getElementById(id);
        if(elm != null)
        {
            document.getElementById(id).setAttribute("class","dropdown-menu dropdown-menu-right")
        }
    }
    render() {
        let pathnames = document.location.href;
        let pathArray = pathnames.split('/');
        let pageName = '/'+pathArray[pathArray.length -1];
        var searchName;
        if(pageName== '/topbar-with-load-more')
        {
            searchName="/topbar-with-load-more"
        }
        else if(pageName== '/sidebar-without-lazyload')
        {
            searchName="/sidebar-without-lazyload"
        }
        else if(pageName== '/topbar-without-lazyload')
        {
            searchName="/topbar-without-lazyload"
        }
        else if(pageName== '/sidebar-with-lazyload')
        {
            searchName="/sidebar-with-lazyload"
        }
        else
        {
            searchName="/sidebar-with-load-more"
        }
        if (this.state.timeout == true) {
            setTimeout(function () {
                this.setState({ timeout: false });
            }.bind(this), 2000);  // wait 5 seconds, then reset to false
        }
        return (

            <header className="site-header header-style-menu-center" id="site-header">
                {this.state.timeout == false ?
                    <div>
                        <div className="topbar topbar-bg-color-default topbar-desktop-on topbar-mobile-off">
                            <div className="container-fluid">
                                <Row>
                                    <Col lg={6} sm={12}>
                                    </Col>
                                    <Col lg={6} sm={12}>
                                        <div className="topbar-right text-right" style={{color: 'white'}}>
                                            <div className="topbar-link">
                                                <ul>
                                                    <li className="topbar_item topbar_item_type-topbar_menu">
                                                        <div className="menu-top-bar-menu-container">
                                                            <ul className="top-menu list-inline">
                                                                {
                                                                    this.props.users.isLoggedIn ?
                                                                        <li className="menu-item">
                                                                            <Link to="/profile">
                                                                                <img src={this.props.users.profile.photo} style={{
                                                                                    width: 50,
                                                                                    height: 50,
                                                                                    objectFit: 'cover',
                                                                                    marginRight: 15,
                                                                                    borderRadius: 100
                                                                                }}/>
                                                                                {this.props.users.profile.name}
                                                                            </Link>
                                                                        </li> :
                                                                        <>
                                                                            <li>
                                                                                <Link to="#" onClick={() => {
                                                                                    this.toggle();
                                                                                    this.logintoggle('1');
                                                                                }} data-toggle="modal" data-target="#"><i className="fa fa-sign-in">&nbsp;</i> Masuk</Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link to="#" onClick={() => {
                                                                                    this.toggle();
                                                                                    this.logintoggle('2');
                                                                                }} data-toggle="modal" data-target="#"> Daftar</Link>
                                                                            </li>
                                                                        </>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="header-main header-main-bg-color-default">
                            <div className="container-fluid">
                                <Row>
                                    <Col lg={12}>
                                        <div className="row align-items-center justify-content-md-center">
                                            <Col xl={2} lg={2} className="col-6">
                                                <div className="logo-wrapper">
                                                    <Link to="/">
                                                        <img className="img-fluid w-auto" src={logo} alt="logo" />
                                                    </Link>
                                                </div>
                                                <div className="clearfix" />
                                            </Col>
                                            <div className="col" id="mainMenu">
                                                <div className="header-nav header-nav-bg-color-default">
                                                    <div className="header-nav-wrapper">
                                                        <Container>
                                                            <Row>
                                                                <div className="col-12">
                                                                    <div className="primary-nav">
                                                                        <div className="primary-nav-wrapper">
                                                                            <nav className="mega-menu">
                                                                                <div class="menu-list-items">
                                                                                     <Navbar light expand="md" class="front_menu" >
                                                                                        <NavbarToggler onClick={this.toggle} />
                                                                                        <Collapse isOpen={this.state.isOpen} navbar>
                                                                                            {navLinks.map((navLink, index) => (
                                                                                                <Nav className="ml-auto" navbar>
                                                                                                    {(navLink.type && navLink.type === 'subMenu') ?
                                                                                                        <Fragment>
                                                                                                            <UncontrolledDropdown nav inNavbar onMouseEnter={()=>this.OpenSubmenuOpen(`submenu_${index}`)} onMouseLeave={()=>this.OpenSubmenuClose(`submenu_${index}`)}>
                                                                                                                <Link aria-haspopup="true" to={navLink.path} className="dropdown-toggle nav-link" aria-expanded="true"> {navLink.menu_title}</Link>
                                                                                                                <DropdownMenu right id={`submenu_${index}`}>

                                                                                                                    {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                                                                        <DropdownItem tag={Link}  className={`nav-item  ${(pageName == subNavLink.path || (subNavLink.path == "/shop/clothing/29" && pageName == "/29")) ? 'active' : '' }`} to={subNavLink.path}>{subNavLink.menu_title}</DropdownItem>
                                                                                                                    ))}
                                                                                                                </DropdownMenu>
                                                                                                            </UncontrolledDropdown>
                                                                                                    </Fragment>
                                                                                                    :
                                                                                                    <Fragment>
                                                                                                        <NavItem>
                                                                                                            <Link to={navLink.path} c className={'nav-link'}>{navLink.menu_title}</Link>
                                                                                                        </NavItem>

                                                                                                    </Fragment>
                                                                                                    }
                                                                                                </Nav>
                                                                                             ))}
                                                                                        </Collapse>
                                                                                    </Navbar>
                                                                                </div>
                                                                            </nav>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </Container>
                                                    </div>
                                                </div>
                                            </div>
                                            <Col xl={2} lg={2} className="col-6">
                                                <div className="header-nav-right-wrapper">
                                                    <nav className="mega-menu">
                                                        <div className="menu-list-items">
                                                            <Navbar light expand="md" class="front_menu">
                                                                <NavbarToggler onClick={this.toggle}/>
                                                                <Collapse isOpen={this.state.isOpen} navbar>
                                                                    {[].map((navLink, index) => (
                                                                        <Nav className="ml-auto" navbar>
                                                                            {(navLink.type && navLink.type === 'subMenu') ?
                                                                                <Fragment>
                                                                                    <UncontrolledDropdown nav inNavbar
                                                                                                          onMouseEnter={() => this.OpenSubmenuOpen(`submenu_${index}`)}
                                                                                                          onMouseLeave={() => this.OpenSubmenuClose(`submenu_${index}`)}>
                                                                                            <Link aria-haspopup="true"
                                                                                                  to={navLink.path}
                                                                                                  className="dropdown-toggle nav-link"
                                                                                                  aria-expanded="true">
                                                                                                {navLink.menu_title}
                                                                                            </Link>

                                                                                        <DropdownMenu right
                                                                                                      id={`submenu_${index}`}>

                                                                                            {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                                                <Fragment>
                                                                                                    {subNavLink.path ?
                                                                                                        <DropdownItem tag={Link}
                                                                                                                      className={`nav-item  ${(pageName == subNavLink.path || (subNavLink.path == "/shop/clothing/29" && pageName == "/29")) ? 'active' : ''}`}
                                                                                                                      to={subNavLink.path}>{subNavLink.menu_title}</DropdownItem> :
                                                                                                        <Button
                                                                                                            className={'nav-item dropdown-item'}
                                                                                                            onClick={() => subNavLink.click(this.props)}
                                                                                                        >
                                                                                                            {subNavLink.menu_title}
                                                                                                        </Button>
                                                                                                    }
                                                                                                </Fragment>
                                                                                            ))}
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </Fragment>
                                                                                :
                                                                                <Fragment>
                                                                                    {navLink.path ?
                                                                                        <NavItem>
                                                                                            <NavLink href={navLink.path}>{navLink.menu_title}</NavLink>
                                                                                        </NavItem> :
                                                                                        <NavItem>
                                                                                            <a
                                                                                                href={'#'}
                                                                                                className={'nav-link'}
                                                                                                onClick={() => navLink.click(this)}
                                                                                            >
                                                                                                {navLink.menu_title}
                                                                                            </a>
                                                                                        </NavItem>
                                                                                    }

                                                                                </Fragment>
                                                                            }
                                                                        </Nav>
                                                                    ))}
                                                                </Collapse>
                                                            </Navbar>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </Col>
                                            <Navbar color="faded" light >

                                                                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                                                                <Collapse isOpen={!this.state.collapsed} navbar>
                                                                <Nav className="ml-auto" navbar>
                                                                {navLinks.map((navLink, index) => (
                                                                                         <li className={`nav-item ${(this.state.classset == navLink.menu_title) ? 'show' : '' }`}>
                                                                                            {(navLink.type && navLink.type === 'subMenu') ?
                                                                                                <Fragment>
                                                                                                            <Link href="#" className="nav-link" onClick={()=>this.onClickClassAdd(navLink.menu_title)}>{navLink.menu_title}</Link>
                                                                                                            <ul className={(this.state.classset == navLink.menu_title) ? 'showcollapsed' : 'submenu' }>
                                                                                                                {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                                                                                    <li  className={`nav-item  ${(pageName == subNavLink.path) ? 'active' : '' }`} toggle={false}   >
                                                                                                                        <Link className="nav-link"  onClick={() => this.closeNavbar()} to={subNavLink.path}>{subNavLink.menu_title}</Link>
                                                                                                                    </li>
                                                                                                               ))}
                                                                                                            </ul>
                                                                                                </Fragment>
                                                                                                :
                                                                                                <Fragment>
                                                                                                     <NavItem>
                                                                                                        <Link to={navLink.path} className="nav-admin-link" >{navLink.menu_title}</Link>
                                                                                                     </NavItem>

                                                                                                </Fragment>
                                                                                                }
                                                                                            </li>
                                                                                            ))}
                                                                                    </Nav>
                                                                   </Collapse>
                                                            </Navbar>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-login modal-dialog-centered">
                                        <ModalHeader toggle={this.toggle}>
                                            <h4 className="mb-0">Masuk atau Daftar</h4>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}
                                                        onClick={() => { this.logintoggle('1'); }}
                                                    >
                                                        Masuk
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === '2' })}
                                                        onClick={() => { this.logintoggle('2'); }}
                                                    >
                                                        Daftar
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId="1">
                                                    <form>
                                                        <div class="form-group">
                                                            <label>E-mail</label>
                                                            <input name='email' type="email" class="form-control" onChange={e => this.setState({emailLogin: e.target.value})} value={this.state.emailLogin} />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Kata Sandi </label>
                                                            <input name='password' type="password" class="form-control" onChange={e => this.setState({passwordLogin: e.target.value})} value={this.state.passwordLogin}/>
                                                        </div>

                                                        <div class="form-group">
                                                            <button className="btn btn-primary mt-1" onClick={this.handleLogin} >Masuk</button>
                                                            <Link className="btn btn-secondary ml-2 mt-1" onClick={this.toggle} >Batal</Link>
                                                        </div>
                                                        <p className="mb-0">Tidak punya akun? <Link to="#" className={classnames({ active: this.state.activeTab === '2' })}
                                                            onClick={() => { this.logintoggle('2'); }} > Daftar </Link></p>
                                                    </form>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <form>
                                                        <div className="form-group">
                                                            {
                                                                this.state.photo_preview &&
                                                                <img style={{height: 150, width: 150, objectFit: 'cover', borderRadius: 100}} src={this.state.photo_preview} className={'d-block mx-auto'} alt=''/>
                                                            }
                                                            <label>Foto</label>
                                                            <input name='photo' type="file" className="form-control" onChange={this.handleChangePhoto} />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Nama</label>
                                                            <input name='name' type="text" class="form-control" onChange={e => this.setState({name: e.target.value})} value={this.state.name}/>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>E-mail</label>
                                                            <input name='email' type="email" class="form-control" onChange={e => this.setState({email: e.target.value})} value={this.state.email}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Nomor HP</label>
                                                            <input name='phone' type="text" className="form-control" placeholder="Nomor HP..." onChange={e => this.setState({phone: e.target.value})} value={this.state.phone} required />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="d-block">Jenis Kelamin</label>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="male" name="gender" className="custom-control-input" value="p" checked={this.state.gender === "p"} onChange={e => this.setState({gender: e.target.value})} required />
                                                                <label htmlFor="male" className="custom-control-label" >Pria</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="female" name="gender" className="custom-control-input" value="w"  checked={this.state.gender === "w"} onChange={e => this.setState({gender: e.target.value})}  required />
                                                                <label htmlFor="female" className="custom-control-label" >Wanita</label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Tanggal lahir</label>
                                                            <input name='birth_date' type="date" className="form-control" onChange={e => this.setState({birth_date: e.target.value})} value={this.state.birth_date}/>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Kata Sandi </label>
                                                            <input name='password' type="password" class="form-control" onChange={e => this.setState({password: e.target.value})} value={this.state.password} />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Konfirmasi Kata Sandi </label>
                                                            <input type="password" class="form-control" onChange={e => this.setState({confirm_password: e.target.value})} value={this.state.confirm_password} />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Bidang</label>
                                                            <Select
                                                                options={this.props.pii_event.bidang}
                                                                isMulti
                                                                placeholder={'Pilih bidang...'}
                                                                onChange={val => {
                                                                    let bidang_ids = val.map(v => v.value);

                                                                    this.setState({
                                                                        bidang_data: val,
                                                                        bidang_ids: JSON.stringify(bidang_ids),
                                                                    })
                                                                }}
                                                                value={this.state.bidang_data}
                                                            />
                                                        </div>
                                                        <div class="form-group">
                                                            <button className="btn btn-primary" onClick={this.handleRegister} >Daftar</button>
                                                            <Link className="btn btn-secondary ml-2" onClick={this.toggle} >Batal</Link>
                                                        </div>
                                                        <p className="mb-0">Sudah punya akun? <Link to="#" className={classnames({ active: this.state.activeTab === '1' })}
                                                            onClick={() => { this.logintoggle('1'); }} > Masuk </Link></p>
                                                    </form>
                                                </TabPane>
                                            </TabContent>
                                        </ModalBody>
                                    </Modal>
                                    <div className="col-12">
                                        <div className="mobile-menu" id="mobileMenu" />
                                     </div>
                                </Row>
                            </div>
                        </div>
                    </div>

                :

                    <div id="preloader">
                        <Loader
                            type="Puff"
                            color="#EB6C24"
                            height="100"
                            width="100"
                        />
                    </div>
                }
            </header>

        )
    }
};

const AppMapStateToProps = state => {
    return {
        users: state.users,
        pii_event: state.pii_event,
    };
};

const AppMapDispatchToProps = dispatch => {
    return {
        setIsLoggedIn: (isLoggedIn) => {
            dispatch({type: 'SET_IS_LOGGED_IN', payload: isLoggedIn})
        },
        login: (payload) => {
            dispatch({type: 'LOGIN', payload})
        }
    };
};

export default connect(AppMapStateToProps, AppMapDispatchToProps)(withRouter(Header));
