import React, {useState, useEffect} from 'react';
import {Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import PageTitle from "../../widgets/PageTitle";
import {Link} from "react-router-dom";
import PiiEventSlider from "./slider";
import Loader from "react-loader-spinner";
import StarRatingComponent from 'react-star-rating-component';
import moment from 'moment';
import 'moment/locale/id';
import {useSelector} from "react-redux";
import API from "../../network";
moment.locale('id');

const DetailEvent = (props) => {

    const user = useSelector(state => state.users.profile);

    const [id, setId] = useState(parseInt(props.match.params.id));
    const [piiEvent, setPiiEvent] = useState(null);
    const [piiEventRating, setPiiEventRating] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isRatingFilled, setIsRatingFilled] = useState(false);
    const [isReviewed, setIsReviewed] = useState(false);

    const [showModalUlasan, setShowModalUlasan] = useState(false);

    const [ulasan, setUlasan] = useState('');

    const [starRatingHover, setStarRatingHover] = useState(0);
    const [starRating, setStarRating] = useState(0);

    const [totalRating, setTotalRating] = useState(0);

    const toggleModalUlasan = () => setShowModalUlasan(!showModalUlasan);

    const submitUlasan = () => {
        let temp_piiEventRating = [...piiEventRating];

        temp_piiEventRating.unshift({
            user,
            rating: starRating,
            review: ulasan,
            date: moment().format('DD MMMM YYYY')
        });

        setPiiEventRating(temp_piiEventRating);
        setShowModalUlasan(false);
        setUlasan('');
        setStarRating(0);
        setStarRatingHover(0);
    };

    const getEventRating = (eventPii) => {
        let data_piiEventRating = [];

        eventPii.reviews.map(v => {
            data_piiEventRating.push(
                {
                    user: {
                        name: v.user.name,
                        photo: v.user.photo_to_user,
                    },
                    rating: v.rating,
                    review: v.review,
                    date: moment(v.created_at).format('DD MMMM YYYY')
                }
            )
        });

        setPiiEventRating(data_piiEventRating);
    };

    const countRating = (data_piiEventRating) => {
        let data_totalRating = 0;

        data_piiEventRating.map(v => {
            data_totalRating += v.rating;
        });

        data_totalRating = data_totalRating / (data_piiEventRating.length * 5) * 5;
        data_totalRating = parseFloat(data_totalRating.toFixed(1));

        setTotalRating(Number.isNaN(data_totalRating) ? 0 : data_totalRating);
    };

    const handleFollow = () => {
        API.follow.follow(piiEvent.penyelenggara_data.id);

        setPiiEvent({
            ...piiEvent,
            penyelenggara_data: {
                ...piiEvent.penyelenggara_data,
                is_followed: !piiEvent.penyelenggara_data.is_followed
            }
        });
    };

    const getEventPii = async () => {
        let {eventPii} = await API.event.getOne(id);
        eventPii.started_at = moment(eventPii.started_at);
        eventPii.finished_at = moment(eventPii.finished_at);
        eventPii.date = moment(eventPii.date);
        setIsReviewed(eventPii.has_reviewed);
        setPiiEvent(eventPii);
        getEventRating(eventPii);
        setIsLoading(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getEventPii();
    }, []);

    useEffect(() => {
        setIsRatingFilled(ulasan !== '' && starRating > 0)
    }, [ulasan, starRating]);

    useEffect(() => {
        countRating(piiEventRating)
    }, [piiEventRating]);

    return (
        isLoading ?
        <div id="preloader">
            <Loader
                type="Puff"
                color="#EB6C24"
                height="100"
                width="100"
            />
        </div> :
        <div className="site-content">
            <div className="content-wrapper">
                <div className="section-wrapper single-post section-ptb pb-5">
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={12} xl={12}>
                                <article className="post format-standard">
                                    <div className="post-entry-image clearfix">
                                        <img src={piiEvent.thumbnail_for_user} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="entry-header-section">
                                        <div className="entry-meta-date">
                                            <h3>{piiEvent.title}</h3>
                                        </div>
                                    </div>

                                    <div className="entry-content">
                                        <Row>
                                            <Col md={4} className={'mb-3'}>
                                                <h6>Diselenggarakan oleh</h6>
                                                <img
                                                    src={piiEvent.penyelenggara_data.photo_for_user}
                                                    className="img-fluid penyelenggara" alt="post-img" />
                                                <span>{piiEvent.penyelenggara_data.name}</span>
                                            </Col>
                                            <Col md={4} className={'mb-3'}>
                                                <h6>Tanggal dan Waktu</h6>
                                                <div className="latest-post-wrapper">
                                                    <div className="latest-post-item">
                                                        <div className="latest-post-meta">
                                                            <ul>
                                                                <li>
                                                                    <i className="fa fa-calendar" />
                                                                    <Link to="#" rel="category tag">{piiEvent.date.format('dddd, DD MMMM YYYY')}</Link>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li>
                                                                    <i className="fa fa-clock-o" />
                                                                    <Link to="#" rel="category tag">{piiEvent.started_at.format('HH:mm')} - {piiEvent.finished_at.format('HH:mm')}</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4} className={'mb-3'}>
                                                <h6>Lokasi</h6>
                                                <div className="latest-post-wrapper">
                                                    <div className="latest-post-item">
                                                        <div className="latest-post-meta">
                                                            <ul>
                                                                <li>
                                                                    <i className="fa fa-map-marker" />
                                                                    <Link to="#" rel="category tag">{piiEvent.location_data}</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <p className='short-description'>
                                                    {piiEvent.short_description}
                                                </p>
                                            </Col>
                                            {moment(`${piiEvent.date.format('YYYY-MM-DD')} ${piiEvent.finished_at.format('HH:mm')}`).isBefore(moment()) ?
                                                <Col md={12} className={'my-auto'}>
                                                    <h3 className={'text-center'} style={{color: '#E6672B', fontWeight: 'bold'}}>Event selesai</h3>
                                                </Col> :
                                                <>
                                                    <Col md={6} className={'my-auto'}>
                                                        <p style={{ fontWeight: 'bold', color: '#04A981', textAlign: 'center'}}>FREE</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Link className={'btn btn-primary w-100'} to={`/event/${id}/checkout`} >Ikut Event</Link>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </div>

                                    {moment(`${piiEvent.date.format('YYYY-MM-DD')} ${piiEvent.finished_at.format('HH:mm')}`).isBefore(moment()) ?
                                        <Row>
                                            <Col md={12}>
                                                <h3 className={'text-center'} style={{color: '#E6672B'}}>Ulasan</h3>
                                                <h1 style={{marginBottom: 0}}>{totalRating}</h1>
                                                <div style={{fontSize: 30, height: 50}}>
                                                    <StarRatingComponent
                                                        editing={false}
                                                        starCount={5}
                                                        value={parseInt(totalRating)}
                                                    />
                                                </div>
                                                <h5>Reviews</h5>
                                                <h5>({piiEventRating.length})</h5>
                                            </Col>
                                            {
                                                piiEvent.is_bought &&
                                                    <Col md={12} className={'text-right'}>
                                                        <Button disabled={isReviewed} color={'primary'} onClick={() => setShowModalUlasan(true)}>
                                                            <div className="fa fa-pencil mr-3"/>
                                                            {
                                                                isReviewed ?
                                                                    'Anda sudah memberi ulasan' :
                                                                    'Tulis Ulasan'
                                                            }
                                                        </Button>
                                                    </Col>
                                            }
                                            {
                                                piiEventRating.map(v => (
                                                    <Col md={12} className={'mt-3'}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className={'d-block'}>
                                                                    <img style={{height: 70, width: 70, borderRadius: 50, objectFit: 'cover', marginRight: 15}} src={v.user.photo} alt=""/>
                                                                    <span style={{fontSize: '16pt'}}>{v.user.name}</span>
                                                                </div>
                                                                <div style={{fontSize: 20, height: 30}}>
                                                                    <StarRatingComponent
                                                                        name={'rating'}
                                                                        editing={false}
                                                                        starCount={5}
                                                                        value={v.rating}
                                                                    />
                                                                </div>
                                                                <p>{v.date}</p>
                                                                <p>{v.review}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                        </Row> :
                                        <>
                                            <div className="entry-content">
                                                <h6>Deskripsi</h6>
                                                <div dangerouslySetInnerHTML={{__html: piiEvent.description}} />
                                            </div>

                                            <div className="entry-content">
                                                <h6>Benefit yang akan didapatkan :</h6>
                                                <ul>
                                                    {piiEvent.benefits_data.map(v => (
                                                        <li>{v}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className="entry-content">
                                                <h6>Seminar ini direkomendasikan untuk :</h6>
                                                <ul>
                                                    {piiEvent.recommended_for_data.map(v => (
                                                        <li>{v}</li>
                                                    ))}

                                                </ul>
                                            </div>

                                            <div className="entry-content interest">
                                                <h6>Interests</h6>
                                                <Row>
                                                    {piiEvent.bidang_data.map(v => (
                                                        <Col sm={12} md={3} className={'mb-4'}>
                                                            <span>{v.name}</span>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>

                                            <footer className="entry-footer clearfix">
                                                <div className="entry-social share pull-right">
                                                    <Link className="share-button" data-title="Share">
                                                        <i className="fa fa-share-alt"></i>
                                                    </Link>
                                                    <ul className="single-share-box mk-box-to-trigger">
                                                        <li>
                                                            <a target="_blank" href="https://www.facebook.com"
                                                               className="share-link facebook-share">
                                                                <i className="fa fa-facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" href="https://twitter.com/"
                                                               className="share-link twitter-share">
                                                                <i className="fa fa-twitter"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" href="https://linkedin.com/"
                                                               className="share-link linkedin-share">
                                                                <i className="fa fa-linkedin"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" href="https://google.com/"
                                                               className="share-link google-share">
                                                                <i className="fa fa-google"></i>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a target="_blank" href="https://in.pinterest.com/"
                                                               className="share-link pinterest-share">
                                                                <i className="fa fa-pinterest"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </footer>
                                        </>
                                    }

                                    <div className="entry-content organizer mt-3">
                                        <Row className={'text-center'}>
                                            <Col md={12}>
                                                <img src={piiEvent.penyelenggara_data.photo_for_user} alt=""/>
                                            </Col>
                                            <Col md={12}>
                                                <Link to={`/organizer/${piiEvent.penyelenggara_data.id}`}>
                                                    <span>{piiEvent.penyelenggara_data.name}</span>
                                                </Link>
                                            </Col>
                                            <Col md={12}>
                                                <h6>Organizer of {piiEvent.title}</h6>
                                            </Col>
                                            <Col md={12}>
                                                <p>{piiEvent.penyelenggara_data.about}</p>
                                            </Col>
                                            <Col md={12}>
                                                <Button onClick={handleFollow} color={'primary'}>
                                                    {piiEvent.penyelenggara_data.is_followed ? 'Unfollow' : 'Follow'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>

                                </article>

                                <div className="related-posts">
                                    <h3 className="title">Event lain dari penyelenggara ini</h3>
                                    <div className="related-posts-section">
                                        <PiiEventSlider />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <Modal isOpen={showModalUlasan} fade={false} toggle={toggleModalUlasan} className="modal-add-user modal-dialog-centered modal-lg">
                <ModalHeader className={'mb-3'} toggle={toggleModalUlasan}>Diulas oleh {user.name}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>Rating anda : </label>
                        <div style={{fontSize: 30}}>
                            <StarRatingComponent
                                starCount={5}
                                value={starRatingHover}
                                onStarClick={(nextValue) => setStarRating(nextValue)}
                                onStarHover={(nextValue) => setStarRatingHover(nextValue)}
                                onStarHoverOut={() => setStarRatingHover(starRating)}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Ulasan anda : </label>
                        <textarea onChange={(e) => setUlasan(e.target.value)} cols="30" rows="10" placeholder="Bagikan pendapat Anda tentang webinar ini...." className={'form-control'}>
                            {ulasan}
                        </textarea>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModalUlasan}>Batalkan</Button> {' '}
                    <Button color="primary" onClick={submitUlasan} disabled={!isRatingFilled}>Kirim</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DetailEvent;
