import swal from "sweetalert2";

export function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item))
}

export function setWithoutExpiry(key, value) {
    let item = JSON.parse(localStorage.getItem(key));
    item.value = value;

    localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    console.log('now.getTime()', now.getTime());
    console.log('item.expiry', item.expiry);
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.clear();
        return null
    }
    return item.value
}

export function swalLoading() {
    swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
            swal.showLoading()
        }
    });
}

export function swalCloseLoading(){
    swal.close();
}

export function filterQueryString(data){
    return data && Object.keys(data).map(key => key + '=' + data[key]).join('&')
}
