import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col, Nav, NavItem } from 'reactstrap';
import {useDispatch} from "react-redux";
import API from "../../network";

const Sitebar = () => {
    const dispatch = useDispatch();

    const setIsLoggedIn = (data) => dispatch({type: 'SET_IS_LOGGED_IN', payload: data});
    const setProfile = (data) => dispatch({type: 'SET_PROFILE', payload: data});

    const [sitebarContents, setSitebarContents] = useState([
        {
            name: 'Profil',
            link: 'profile',
            is_active: false
        },
        {
            name: 'Event',
            link: 'user-events',
            is_active: false
        },
        {
            name: 'Following',
            link: 'following',
            is_active: false
        },
        {
            name: 'Blog yang tersimpan',
            link: 'saved-blogs',
            is_active: false
        },
        {
            name: 'Keluar',
            link: 'logout',
            is_active: false,
            onClick: async () => {
                setIsLoggedIn(false);
                setProfile({});
                localStorage.clear();
                API.user.logout();
                window.location.href = '/';
            }
        },
    ]);


    useEffect(() => {
        SetActiveClass();
    }, []);

    const SetActiveClass = () => {
        let temp_sitebarContents = [...sitebarContents];
        temp_sitebarContents = temp_sitebarContents.map(v => {
           v.is_active = false;
           return v;
        });

        let pathname = document.location.href;
        let pathArray = pathname.split('/');
        let pageName = pathArray[pathArray.length -1];

        let selected_idx = temp_sitebarContents.indexOf(temp_sitebarContents.filter(v => pageName.includes(v.link))[0]);
        temp_sitebarContents[selected_idx].is_active = true;

        setSitebarContents(temp_sitebarContents);
    };

    return (
        <Fragment>
            <Col lg={3}>
                <div className="woocommerce-MyAccount-navigation">
                    <Nav className="flex-column">
                        {sitebarContents.map(v => (
                            <NavItem>
                                {v.onClick ?
                                    <Link className={`adminprofile nav-link admin-nav`} to={`#`} onClick={v.onClick}> {v.name}</Link> :
                                    <Link className={`${v.is_active ? 'active' : '' } adminprofile nav-link admin-nav`} to={`/${v.link}`}> {v.name}</Link>
                                }
                            </NavItem>
                        ))}
                    </Nav>
                </div>
            </Col>
        </Fragment>
    )
}
export default Sitebar;
