import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Sitebar from "./sitebar";
import ProfileDetail from "./ProfileDetail";
import {useDispatch, useSelector} from "react-redux";
import EventItem from "../event/item";
import moment from 'moment';
import StarRatingComponent from "react-star-rating-component";
import BlogReleaseItem from "../BlogRelease/item";

const SavedBlogs = () => {

  const blogRelease = useSelector(state => state.blog_release.blogRelease).filter(v => v.is_saved);
  const [savedBlogRelease, setSavedBlogRelease] = useState(blogRelease.filter(v => v.is_saved));

  return (
    <div className="section-ptb">
        <Container>
            <ProfileDetail />
            <Row>
                <Sitebar />
                <Col lg={9} className="mt-4 mt-lg-0">
                    <Row>
                        <Col lg={12}>
                            <div className="latest-post-wrapper latest-post-type-carousel latest-post-style-2 latest-post-without-intro">
                                <Row>
                                    {
                                        savedBlogRelease.map(v => (
                                            <Col sm={4} className={'mb-3'}>
                                                <BlogReleaseItem {...v}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
   )
}
export default SavedBlogs;
