import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AboutBanner from '../../widgets/AboutBanner.js';
import HomeSlider from '../../widgets/HomSlider.js';
import PiiEventSlider from "../event/slider";
import {useDispatch, useSelector} from "react-redux";
import API from "../../network";

const HomePage = () => {
    const dispatch = useDispatch();

    const bidang = useSelector(state => state.pii_event.bidang);
    const daerah = useSelector(state => state.pii_event.daerah);

    const setPiiEvents = (data) => dispatch({type: 'SET_PII_EVENTS', payload: data});
    const setBanners = (data) => dispatch({type: 'SET_BANNERS', payload: data});

    const [eventPiiFilter, setEventPiiFilter] = useState({sort_date: 'desc'});

    useEffect(() => {
        window.scrollTo(0, 0);
        getBanners();
    }, []);

    useEffect(() => {
        getEventPiis();
    }, [eventPiiFilter]);

    const getEventPiis = async () => {
        try{
            let data = await API.event.get(eventPiiFilter);
            console.log(data);
            setPiiEvents(data.events);
        }catch (e) {
            console.log(e)
        }
    };

    const getBanners = async () => {
        try{
            let data = await API.user.banner();
            console.log(data);
            setBanners(data.banners);
        }catch (e) {
            console.log(e)
        }
    };

    return (
        <div>
            <HomeSlider />
            <div id="content" className="site-content" tabIndex={-1}>
                <div className="content-wrapper content-wrapper-vc-enabled">
                    <div id="primary" className="content-area">
                        <main id="main" className="site-main">
                            <article id="post-13069" className="post-13069 page type-page status-publish hentry">
                                <div className="entry-content">
                                    <Container>
                                        <Row className="section-pt">
                                            <Col sm={12}>
                                                <Row className={'mb-3'}>
                                                    <Col sm={3} className={'my-auto'}>
                                                        <div className="section-title my-auto">
                                                            <h2 className="title text-left">Event</h2>
                                                        </div>
                                                    </Col>
                                                    <Col sm={3} className={'my-auto'}>
                                                        <select className={'form-control'} name="daerah" onChange={e => setEventPiiFilter({...eventPiiFilter, location_id: e.target.value})}>
                                                            <option disabled selected>Pilih daerah...</option>
                                                            {
                                                                daerah.map(v => (
                                                                    <option value={v.id} onChange={e => setEventPiiFilter({...eventPiiFilter, location_id: e.target.value})}>{v.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Col>
                                                    <Col sm={3} className={'my-auto'}>
                                                        <select className={'form-control'} name="topik" onChange={e => setEventPiiFilter({...eventPiiFilter, bidang_id: e.target.value})}>
                                                            <option disabled selected>Pilih topik...</option>
                                                            {
                                                                bidang.map(v => (
                                                                    <option value={v.value}>{v.label}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </Col>
                                                    <Col sm={3} className={'my-auto mb-3'}>
                                                        <p>Pilih tanggal : </p>
                                                        <input type="date" className={'form-control mb-3'} onChange={e => setEventPiiFilter({...eventPiiFilter, date_from: e.target.value})} value={eventPiiFilter.date_from} />
                                                        <input type="date" className={'form-control'} onChange={e => setEventPiiFilter({...eventPiiFilter, date_to: e.target.value})} value={eventPiiFilter.date_to} />
                                                    </Col>
                                                </Row>
                                                <PiiEventSlider />
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div className="container section-3">
                                        <AboutBanner />
                                    </div>
                                </div>
                            </article>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage;
