import moment from 'moment';

export default (state = {
    categories: [
        {
            "value": 1,
            "label": "Kategori 1"
        },
        {
            "value": 2,
            "label": "Kategori 2"
        },
        {
            "value": 3,
            "label": "Kategori 3"
        },
        {
            "value": 4,
            "label": "Kategori 4"
        },
        {
            "value": 5,
            "label": "Kategori 5"
        }
    ],
    blogRelease: []
}, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'SET_BLOG_RELEASE_CATEGORIES':
            return {
                ...state,
                categories: payload
            };
        case 'SET_BLOG_RELEASE':
            return {
                ...state,
                blogRelease: payload
            };
        default:
            return {
                ...state
            };
    }
};
