import React, {useState, useEffect} from 'react';
import {Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import PageTitle from "../../widgets/PageTitle";
import {Link} from "react-router-dom";
import PiiEventSlider from "./../event/slider";
import Loader from "react-loader-spinner";
import moment from 'moment';
import 'moment/locale/id';
import {useSelector} from "react-redux";
moment.locale('id');

const Organizer = () => {

    const piiEvents = useSelector(state => state.pii_event.piiEvents);

    const [organizer, setOrganizer] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setOrganizer({
           name: 'Nama Penyelenggara',
           photo: require('../../assets/images/organizer.png'),
           total_followers: 900,
           about: 'Tincidunt metus donec congue tellus odio arcu. Amet parturient fermentum aliquet adipiscing. Dignissim feugiat sagittis in interdum orci rhoncus adipiscing nulla. Est cras magna in sodales semper at vitae eget. Amet, blandit sed venenatis magna diam nulla. Interdum vel magna sed aenean laoreet sodales. Tincidunt metus donec congue tellus odio arcu. Amet parturient fermentum aliquet adipiscing',
           is_followed: false
        });

        setIsLoading(false);
    }, []);

    const handleFollow = () => {
      setOrganizer({
          ...organizer,
          is_followed: !organizer.is_followed
      })
    };

    return (
        isLoading ?
            <div id="preloader">
                <Loader
                    type="Puff"
                    color="#EB6C24"
                    height="100"
                    width="100"
                />
            </div> :
            <div className="site-content">
                <div className="content-wrapper">
                    <div className="section-wrapper single-post section-ptb pb-5">
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <article className="post format-standard">
                                        <div className="post-entry-image clearfix">
                                            <img src={organizer.photo} className="img-fluid" alt=""/>
                                        </div>
                                        <div className="entry-header-section">
                                            <div className="entry-meta-date text-center">
                                                <h3>{organizer.name}</h3>
                                                <p className={'text-center'}>Menyelenggarakan total {piiEvents.length} event</p>
                                                <p className={'text-center'}>{organizer.total_followers} Followers</p>
                                                <Button onClick={handleFollow} color={'primary'} style={{width: 220}}>
                                                    {organizer.is_followed ? 'Unfollow' : 'Follow'}
                                                </Button>
                                                <p style={{
                                                    textAlign: 'justify',
                                                    margin: '30px auto',
                                                }}>{organizer.about}</p>
                                            </div>
                                        </div>

                                    </article>

                                    <div className="related-posts">
                                        <h3 className="title">Event</h3>
                                        <div className="related-posts-section">
                                            <PiiEventSlider isOrganizer={true} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
    );
};

export default Organizer;
