import React from 'react';
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import StarRatingComponent from "react-star-rating-component";
import API from '../../network';
import { swalCloseLoading, swalLoading } from '../../helpers';

const EventItem = (props) => {
    const dispatch = useDispatch();

    const piiEvents =  useSelector(state => state[(props.isUser || props.isLiked) ? 'users' : 'pii_event'][props.isLiked ? 'likedPiiEvents' : 'piiEvents']);

    const setPiiEvents = (data) => dispatch({type: props.isUser ? 'SET_USER_PII_EVENTS' : (props.isLiked ? 'SET_USER_LIKED_PII_EVENTS' : 'SET_PII_EVENTS'), payload: data});


    const handleLike = () => {
        let temp_piiEvents = [...piiEvents];
        let piiEvent_index = temp_piiEvents.indexOf(temp_piiEvents.filter(v => v.id === props.id)[0]);
        temp_piiEvents[piiEvent_index].is_liked = !temp_piiEvents[piiEvent_index].is_liked;

        setPiiEvents(temp_piiEvents);
    };

    const downloadCertificate = (user_event_id, event_title) => {
        swalLoading();
        API.event.downloadCertificate(user_event_id, event_title)
            .then(() => {
                swalCloseLoading(); 
            });
    }

    return (
        <div className="item entry-content">
            <div className="latest-post-item">
                <div className="latest-post-item-inner">
                    <div className="latest-post-image">
                        <img style={{height: 200, objectFit: 'cover'}} src={props.thumbnail_for_user} className="img-fluid" alt="post-img" />
                    </div>
                    <div className="latest-post-content">
                        <div className="latest-post-meta">
                            <ul>
                                <li>
                                    <i className="fa fa-calendar" />
                                    <Link to="#" rel="category tag">{props.date.format('dddd, DD MMMM YYYY')}</Link>
                                </li>
                                <li>
                                    <img
                                        style={{
                                            height: 20,
                                            position: 'relative',
                                            top: 12,
                                            left: 74,
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleLike}
                                        src={props.is_liked ? require(`../../assets/images/like.png`) : require(`../../assets/images/not-like.png`)}
                                    />
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <i className="fa fa-clock-o" />
                                    <Link to="#" rel="category tag">{props.started_at.format('HH:mm')} - {props.finished_at.format('HH:mm')}</Link>
                                </li>
                            </ul>
                        </div>
                        <h3 className="blog-title">
                            <Link to={`/event/${props.id}`}>
                                {props.title}
                            </Link>
                        </h3>
                        <div className="latest-post-excerpt">
                            <Link to={`/organizer/${props.penyelenggara_data.id}`}>
                                <img
                                    src={props.penyelenggara_data.photo_for_user}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        objectFit: 'cover',
                                        borderRadius: 50,
                                        display: 'inline',
                                        marginRight: 10
                                    }}
                                    className="img-fluid" alt="post-img" />
                                <span>{props.penyelenggara_data.name}</span>
                            </Link>
                        </div>
                        <div className="latest-post-excerpt mt-3 row">
                            <div className="col-6 my-auto">
                                {
                                    moment(`${props.date.format('YYYY-MM-DD')} ${props.started_at.format('HH:mm')}`).isBefore(moment()) ?
                                        <p style={{ fontWeight: 'bold'}}>Selesai</p> :
                                        <p style={{ fontWeight: 'bold', color: '#04A981'}}>FREE</p>
                                }
                            </div>
                            <div className="col-6 my-auto">
                                <p style={{ background: '#F3F3F3', padding: 5, textAlign: 'center'}}>{props.bidang_data[0].name}</p>
                            </div>
                            <div className="col-12 mt-3">
                                {
                                    (props.is_joined || props.isUser) ?
                                        <>
                                            {
                                                moment(`${props.date.format('YYYY-MM-DD')} ${props.started_at.format('HH:mm')}`).isSameOrAfter(moment()) &&
                                                <Button outline color={'primary'} className={'w-100'} onClick={() => {}}>Download Tiket</Button>
                                            }
                                            {
                                                moment(`${props.date.format('YYYY-MM-DD')} ${props.finished_at.format('HH:mm')}`).isBefore(moment()) &&
                                                <Button outline color={'primary'} className={'w-100'} onClick={() => downloadCertificate(props.user_event_id, props.title)} >Download Sertifikat</Button>
                                            }
                                        </> :
                                        <>
                                            {
                                                moment(`${props.date.format('YYYY-MM-DD')} ${props.started_at.format('HH:mm')}`).isSameOrAfter(moment()) ?
                                                <Link className={'btn btn-primary w-100'} to={`/event/${props.id}/checkout`} >Ikut Event</Link> :
                                                <>
                                                    <h4 style={{marginBottom: 0}}>Rating {props.rating}</h4>
                                                    <div style={{fontSize: '22pt', height: 50}}>
                                                        <StarRatingComponent
                                                            editing={false}
                                                            starCount={5}
                                                            value={parseInt(props.rating)}
                                                        />
                                                    </div>
                                                    <p>({props.reviews_count})</p>
                                                </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default EventItem;
