import axios from "axios";
import {RootPath, Headers} from "./Config";

const Download = (path, filename) => {
    const promise = new Promise((resolve, reject) => {
        axios({
            url: `${RootPath}/${path}`,
            method: 'GET',
            responseType: 'blob',
            ...Headers,        
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            resolve('success');
        },(err) => {
            reject(err);
        });
    });

    return promise;
};

export default Download;
