/**
 *  Footer Main
 */
import React from 'react';
import { Row, Col,Container } from 'reactstrap';
import { Link } from 'react-router-dom';

class Footer extends React.Component {

    constructor(props)
    {
        super(props);
    }
    componentDidMount()
    {
        window.addEventListener('scroll',this.Checkscroll);
    }
    componentWillUnmount()
    {
        window.removeEventListener('scroll',this.Checkscroll);
    }

    Checkscroll()
    {

        var scrollTop = (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;

        if(scrollTop > 350)
        {
            if(document.getElementById("back-to-top") != null)
            {
                document.getElementById("back-to-top").setAttribute("style","display:block");
            }
        }
        else
        {

            if(document.getElementById("back-to-top") != null)
            {
                document.getElementById("back-to-top").setAttribute("style","display:none");
            }
        }

    }
    ClicktoTop()
    {
        window.scroll({top: 0, left: 0, behavior: 'smooth' })
    }
    render() {
        let backtotop = {display : 'none'}
        return (
        <div>
                <footer className="site-footer  ">
                <div className="footer-wrapper">
                    <div className="footer-widgets-wrapper">
                    <div className="footer">
                        <Container>
                        <Row>
                            <div className="col-lg-6 col-md-6 footer-align-left">
                                <div className="logo-wrapper widget">
                                    <p>
                                        <Link to="#">
                                            <img className="img-fluid mr-3"  src={require(`../../assets/images/logo.png`)}   alt="logo" />
                                        </Link>
                                        Persatuan Insinyur Indonesia
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 footer-align-left">
                                <div className="pgs-contact-widget widget mt-4 mt-lg-0">
                                    <div className="footer-address">
                                    <ul>
                                        <li>
                                            <i className="fa fa-map-marker" />
                                            <span>
                                                Graha Rekayasa Indonesia
                                                Jl. Halimun No 39 RT 007 / RW 002
                                                Guntur, Setiabudi
                                                Jakarta Selatan - DKI Jakarta
                                            </span>
                                        </li>
                                        <li><i className="fa fa-phone" /><span>0812 9393 7552</span></li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="pgs-social-profiles mt-4">
                                    <div className="social-profiles-wrapper">
                                        <div className="social-profiles-wrapper-inner social-profiles-default social-profiles-shape-square">
                                            <div className="social-profiles">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/persatuaninsinyurindonesia/" title="Facebook" target="_blank"><i className="fa fa-facebook" /></a></li>
                                                    <li><a href="https://twitter.com/pii_pusat" title="Twitter" target="_blank"><i className="fa fa-twitter" /></a></li>
                                                    <li><a href="https://www.instagram.com/pii_pusat" title="Instagram" target="_blank"><i className="fa fa-instagram" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        </Container>
                    </div>
                    </div>
                    <div className="site-info">
                    <div className="footer-widget">
                        <Container>
                        <div className="row align-items-center">
                            <Col md={6} className="float-left">
                                 <p> © Copyright {(new Date()).getFullYear()} <Link to="#">Persatuan Insinyur Indonesia</Link></p>
                            </Col>
                        </div>
                        <div className="clearfix" />
                    </Container>
                    </div>
                    </div>
                  </div>
                </footer>
                 {/* Back to Top */}
                <div id="back-to-top" style={backtotop} onClick={this.ClicktoTop}>
                    <Link class="top arrow">
                            <i class="fa fa-angle-up"></i>
                    </Link>
                </div>
        </div>
        )
    }
};
export default Footer;
