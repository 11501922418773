import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import BlogReleaseItem from "./item";
import {useSelector} from "react-redux";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

const BlogReleaseSlider = () => {

    const blogRelease = useSelector(state => state.blog_release.blogRelease);

    return (
        <div className="latest-post-wrapper latest-post-type-carousel latest-post-style-2 latest-post-without-intro">
            <Slider {...settings} className="slider-spacing-10 slider-arrow-hover">
                {blogRelease.map(v => (
                    <BlogReleaseItem {...v} />
                ))}
            </Slider>
        </div>
    )
};

export default BlogReleaseSlider;


