import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Sitebar from "./sitebar";
import ProfileDetail from "./ProfileDetail";
import {useDispatch, useSelector} from "react-redux";
import API from "../../network";
import Select from 'react-select';
import swal from 'sweetalert2';
import {swalLoading} from "../../helpers";

const EditProfile = () => {
  const dispatch = useDispatch();

  const profileState = useSelector(state => state.users.profile);
  const setProfileState = profile => dispatch({type: 'SET_PROFILE', payload: profile});

  const bidang = useSelector(state => state.pii_event.bidang);

  const [profile, setProfile] = useState(profileState);
  const [photoPreview, setPhotoPreview] = useState(profileState.photo);

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');

  const [bidangData, setBidangData] = useState(profile.bidang_data.map(v => (
      {
          value: v.id,
          label: v.name,
      }
  )));

  const [confirmPassword, setConfirmPassword] = useState('');
  const [isAllFilled, setIsAllFilled] = useState(false);
  const [isPhotoChanged, setIsPhotoChanged] = useState(false);

  const onProfileFormSubmit = async (e) => {
    e.preventDefault();

    swalLoading();

    try{
        let data = {...profile};

        if(oldPassword.length > 0){
            data.old_password = oldPassword;
            data.password = password;
        }

        if(!isPhotoChanged)
            delete data.photo;

        let res = await API.user.update_profile(data);

        profile.bidang_data = res.user.bidang_data;

        if(isPhotoChanged)
            profile.photo = res.user.photo_to_user;

        setProfileState(profile);
        swal.fire('Berhasil ubah profil', '', 'success')
    }catch (e) {
        console.log(e);
        swal.fire('Terjadi kesalahan', JSON.stringify(e), 'error')
    }
  };

  const handleChange = (e, field) => {
    if(field === 'photo'){
        setIsPhotoChanged(true);
        let reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);
        setProfile({...profile, [field]: e.target.files[0]});
        reader.onload = () => {
            setPhotoPreview(reader.result);
        };
    }
    else
        setProfile({...profile, [field]: e.target.value})
  };

  useEffect(() => {
      let check = true;

      if(oldPassword.length > 0)
          check = password === confirmPassword;

      setIsAllFilled(check)
  }, [password, confirmPassword]);

   return (
    <div className="section-ptb">
        <Container>
            <ProfileDetail />
            <Row>
                <Sitebar />
                <Col lg={9} className="mt-4 mt-lg-0">
                    <Row>
                        <Col lg={12}>
                            <div className="woocommerce-Address woocommerce-Address-edit">
                                <div className="woocommerce-Address-title">
                                    <h5 className="mb-0">Profil</h5>
                                </div>
                                <div className="woocommerce-Address-info mt-4">
                                    <form onSubmit={onProfileFormSubmit}>
                                        <div className="form-group">
                                            <img style={{height: 150, width: 150, objectFit: 'cover', borderRadius: 100}} src={photoPreview} className={'d-block mx-auto'} alt=''/>
                                            <label>Foto</label>
                                            <input name='photo' type="file" className="form-control" onChange={e => handleChange(e, 'photo')} />
                                        </div>
                                        <div className="form-group">
                                            <label>Nama</label>
                                            <input name='name' type="text" className="form-control" value={profile.name} placeholder="Nama..." required onChange={e => handleChange(e, 'name')} />
                                        </div>
                                        <div className="form-group">
                                            <label className="d-block">Jenis Kelamin</label>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="male" name="gender" className="custom-control-input" value="p"  checked={profile.gender === "p"} onChange={e => handleChange(e, 'gender')} required />
                                                <label htmlFor="male" className="custom-control-label" >Pria</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="female" name="gender" className="custom-control-input" value="w"  checked={profile.gender === "w"} onChange={e => handleChange(e, 'gender')} required />
                                                <label htmlFor="female" className="custom-control-label" >Wanita</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Tanggal lahir</label>
                                            <input type="date" name='birth_date' className="form-control" placeholder="Tanggal lahir..." value={profile.birth_date} onChange={e => handleChange(e, 'birth_date')} required />
                                        </div>
                                        <div className="form-group">
                                            <label>Nomor HP</label>
                                            <input name='phone' type="text" className="form-control" placeholder="Nomor HP..." value={profile.phone} onChange={e => handleChange(e, 'phone')} required />
                                        </div>
                                        <div className="form-group">
                                            <label>E-mail</label>
                                            <input type="email" className="form-control" placeholder="E-mail..." value={profile.email}  onChange={e => handleChange(e, 'email')} required />
                                        </div>
                                        <div className="form-group">
                                            <label>Bidang</label>
                                            <Select
                                                options={bidang}
                                                isMulti
                                                placeholder={'Pilih bidang...'}
                                                onChange={val => {
                                                    setBidangData(val);
                                                    let bidang_ids = val.map(v => v.value);
                                                    setProfile({...profile, bidang_ids: JSON.stringify(bidang_ids)})
                                                }}
                                                value={bidangData}
                                            />
                                        </div>
                                        <h6>Ubah kata sandi</h6>
                                        <div className="form-group">
                                            <label>Kata Sandi Lama</label>
                                            <input type="password" className="form-control" placeholder="Kata Sandi..." value={oldPassword} onChange={e => setOldPassword(e.target.value)} required={oldPassword.length > 0} />
                                        </div>
                                        <div className="form-group">
                                            <label>Kata Sandi Baru</label>
                                            <input disabled={oldPassword.length < 1} type="password" className="form-control" placeholder="Kata Sandi..." value={password} onChange={e => setPassword(e.target.value)} required={oldPassword.length > 0} />
                                        </div>
                                        <div className="form-group">
                                            <label>Konfirmasi Kata Sandi</label>
                                            <input disabled={oldPassword.length < 1} type="password" className="form-control" placeholder="Konfirmasi Kata Sandi..." value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required={oldPassword.length > 0} />
                                            {password !== confirmPassword && <span className="error">Konfirmasi kata sandi salah</span>}

                                        </div>
                                        <Button type="submit" disabled={!isAllFilled} className="btn btn-primary w-100">Simpan</Button>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
   )
}
export default EditProfile;
