import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return(
        <Row style={{height: '100vh', backgroundColor: '#181818'}}>
            <Col sm={6} className={'h-100 d-table'}>
                <div className="card card-block d-table-cell align-middle text-center" style={{backgroundColor: '#181818'}}>
                    <img className="img-fluid mr-3" style={{height: 353}} src={require(`../../assets/images/logo.png`)}   alt="logo" />
                </div>
            </Col>
            <Col sm={6} className={'h-100 d-table'}>
                <div className="card card-block d-table-cell align-middle" style={{backgroundColor: '#181818'}}>
                    <h4 style={{color: 'white'}}>Social media</h4>
                    <footer className="site-footer">
                        <div className="pgs-social-profiles mt-4">
                            <div className="social-profiles-wrapper">
                                <div className="social-profiles-wrapper-inner social-profiles-default social-profiles-shape-square">
                                    <div className="social-profiles">
                                        <ul>
                                            <li><a href="https://www.facebook.com" title="Facebook" target="_blank"><i className="fa fa-facebook" /></a></li>
                                            <li><a href="https://twitter.com" title="Twitter" target="_blank"><i className="fa fa-twitter" /></a></li>
                                            <li><a href="https://google.com/" title="Google" target="_blank"><i className="fa fa-google" /></a></li>
                                            <li><a href="https://vimeo.com/" title="Vimeo" target="_blank" ><i className="fa fa-vimeo" /></a></li>
                                            <li><a href="https://in.pinterest.com/" title="Pinterest" target="_blank" ><i className="fa fa-pinterest" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pgs-contact-widget widget mt-3">
                            <div className="footer-address">
                                <ul>
                                    <li><i className="fa fa-map-marker" /><span>1635 Franklin Street Montgomery, Near Sherwood Mall. AL 36104</span></li>
                                    <li><i className="fa fa-print" /><span>126-632-2345</span></li>
                                    <li><i className="fa fa-phone" /><span>126-632-2345</span></li>
                                </ul>
                            </div>
                        </div>
                    </footer>

                </div>
            </Col>
        </Row>
    )
};
export default Contact;
