
import React , {Component} from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import {Button} from "reactstrap";
import {useSelector} from "react-redux";
import Loader from "react-loader-spinner";

const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
   slidesToScroll: 1
  };

const HomSlider = () => {

    const banners = useSelector(state => state.pii_event.banners);

    return (
        <Slider className="slider-01 slider-simple-arrow" {...settings}>
            {
                banners.length > 0 ?
                    banners.map(v => (
                        <div key={1} className="slide-01-item">
                            <div className="slide-inner">
                                <div className="slide-image">
                                    <img src={require(`../assets/images/black.png`)} alt="slide-1"/>
                                </div>
                                <div className="slide-content">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-6 col-md-8 my-auto">
                                                <div className="slide-left">
                                                    <div className="slide-sale">{v.title}</div>
                                                    <div className="slide-subtitle">{v.short_description}</div>
                                                    <div className="mt-3">
                                                        <Link to={`/event/${v.id}`} className={'btn btn-primary mr-3'}>Ikut Event</Link>
                                                        <Link to={`/contact`} className={'btn btn-secondary'}>Kontak Kami</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-md-4 text-right d-none d-md-block">
                                                <img src={v.thumbnail_for_user} alt="slide-1"/>
                                                {/*<img src={v.thumbnail} alt="slide-1"/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) :
                    <div key={1} className="slide-01-item">
                        <div className="slide-inner">
                            <div className="slide-image">
                                <img src={require(`../assets/images/black.png`)} alt="slide-1"/>
                            </div>
                            <div className="slide-content text-center">
                                <Loader
                                    type="Puff"
                                    color="#EB6C24"
                                    height="100"
                                    width="100"
                                />
                            </div>
                        </div>
                    </div>
            }
        </Slider>
    )
}

export default HomSlider;
