/**
 * About Banner
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';


class AboutBanner extends Component {

    constructor(){
        super();
        this.state = { showText: false };
      }
      getMoreTextDiv(){
        if (this.state.showText) {
            return  <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>;
          } else {
            return null;
          }
      }
   render() {
    var expandedDiv = this.getMoreTextDiv();
    return (
        <>
            <Row className="section-ptb align-items-center">
                <Col md={6} className="order-md-1 mb-4 mb-md-0">
                    <div className="single_image-wrapper">
                        <img src={require(`../assets/images/artikel.png`)}   className="attachment-full img-fluid" alt />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="section-title mb-4">
                        <h2 className="title text-left"> Be Hybrid Engineer In Industry 4.0</h2>
                        <div className="text-wrapper">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non, amet ac nunc tellus gravida molestie. Suspendisse consectetur dignissim neque auctor sit in ullamcorper et. Bibendum euismod libero, vitae, ac. Arcu erat sed pretium sed quam.</p>
                            { expandedDiv }
                        </div>
                    </div>
                    <div className="ciyashop_button_wrapper ciyashop_button_width_default">
                        <Link to={`blog-release/1`} className={'btn btn-primary'}>Baca artikel</Link>
                    </div>
                </Col>
            </Row>
            <Row className="section-ptb align-items-center">
                <Col md={6} className="order-md-1 mb-4 mb-md-0">
                    <div className="section-title mb-4">
                        <h2 className="title text-left"> Be Hybrid Engineer In Industry 4.0</h2>
                        <div className="text-wrapper">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non, amet ac nunc tellus gravida molestie. Suspendisse consectetur dignissim neque auctor sit in ullamcorper et. Bibendum euismod libero, vitae, ac. Arcu erat sed pretium sed quam.</p>
                            { expandedDiv }
                        </div>
                    </div>
                    <div className="ciyashop_button_wrapper ciyashop_button_width_default">
                        <Link to={`blog-release/1`} className={'btn btn-primary'}>Baca artikel</Link>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="single_image-wrapper">
                        <img src={require(`../assets/images/artikel.png`)}   className="attachment-full img-fluid" alt />
                    </div>
                </Col>
            </Row>
        </>
      )
   }
}

export default AboutBanner;
