import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Sitebar from "./sitebar";
import ProfileDetail from "./ProfileDetail";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router';

const Following = () => {
    const history = useHistory();

  const followings = useSelector(state => state.users.followings);

  return (
    <div className="section-ptb">
        <Container>
            <ProfileDetail />
            <Row>
                <Sitebar />
                <Col lg={9} className="mt-4 mt-lg-0">
                    <Row>
                        {
                            followings.map(v => (
                                <Col md={12} className={'mt-3'}>
                                    <div className="card">
                                        <div className="card-body">
                                            <Row>
                                                <Col md={6} style={{cursor: 'pointer'}} onClick={() => history.push(`organizer/1`)}>
                                                    <img style={{height: 70, width: 70, borderRadius: 50, objectFit: 'cover', marginRight: 15}} src={v.photo} alt=""/>
                                                    <span style={{fontSize: '16pt'}}>{v.name}</span>
                                                </Col>
                                                <Col md={6} className={'my-auto text-right'}>
                                                    <Button color={'primary'}>Unfollow</Button>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
   )
}
export default Following;
