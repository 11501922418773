import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Input, Row } from 'reactstrap';
import PageTitle from '../../widgets/PageTitle';
import BlogReleaseSlider from "./slider";
import Loader from "react-loader-spinner";
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

const BlogReleaseDetail = () => {
    const [blogRelease, setBlogRelease] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);

        setBlogRelease({
            id: 1,
            category: 'Kategori 1',
            title: 'Artikel tentang kemajuan dunia teknik industri di dalam negri',
            description: `<p>I truly believe Augustine’s words are true and if you look at history you know it
                is true. There are many people in the world with amazing talents who realize
                only a small percentage of their potential. We all know people who live this
                truth. we also know those epic stories, those modern-day legends surrounding the
                early failures of such supremely successful folks as Michael Jordan and Bill
                Gates. We can look a bit further back in time to Albert Einstein or even further
                back to Abraham Lincoln. what made each of these people so successful?
                motivation. we know this in our gut, but what can we do about it? how can we
                motivate ourselves? one of the most difficult aspects of achieving success is
                staying motivated over the long haul. motivation will almost always beat mere
                talent.
            </p>
            <p className="mb-0">Motivation is not an accident or something that someone else can give you – you
                are the only one with the power to motivate you. motivation cannot be an
                external force, it must come from within as the natural product of your desire
                to achieve something and your belief that you are capable to succeed at your
                goal. positive pleasure-oriented goals are much more powerful motivators than
                negative fear-based ones. Although each is successful separately, the right
                combination of both is the most powerful motivational force known to humankind.
            </p>`,
            thumbnail: require(`../../assets/images/categories/new-fashion/post-img1.jpg`),
            date: moment('2022-08-14').format('DD MMMM YYYY'),
        });
        setIsLoading(false);
    }, []);

    return (
        isLoading ?
        <div id="preloader">
            <Loader
                type="Puff"
                color="#EB6C24"
                height="100"
                width="100"
            />
        </div> :
        <div className="site-content">
            <div className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                <Container>
                    <Row className="intro-title align-items-center intro-section-center">
                        <PageTitle title="Artikel tentang kemajuan teknik industri di dalam negri"/>
                    </Row>
                </Container>
            </div>
            <div className="content-wrapper">
                <div className="section-wrapper single-post section-ptb pb-5">
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={12} xl={12}>
                                <article className="post format-standard">
                                    <div className="post-entry-image clearfix">
                                        <img src={blogRelease.thumbnail} className="img-fluid" alt="" />
                                    </div>

                                    <div className="entry-header-section">
                                        <div className="entry-meta-date">
                                            <Link to="#">{blogRelease.date}</Link>
                                        </div>
                                        <div className="entry-meta">
                                            <ul>
                                                <li><span className="entry-meta-categories">
                                                        <i className="fa fa-folder-open"></i>&nbsp;
                                                    <Link to="#" rel="category tag">{blogRelease.category}</Link></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="entry-content">
                                        <div dangerouslySetInnerHTML={{__html: blogRelease.description}}/>
                                    </div>
                                </article>
                                <div className="related-posts">
                                    <h3 className="title">Related Article</h3>
                                    <BlogReleaseSlider/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}
export default BlogReleaseDetail;
