/**
 *  header-menu and sidebar menu data
 */
export default [
   {
      "menu_title": "Event",
      "path": "/event",
      "icon": "perm_identity",
      "child_routes": null
   },
   {
      "menu_title": "Blog & Release",
      "path": "/blog-release",
      "icon": "perm_identity",
      "child_routes": null
   },
   {
      "menu_title": "Kontak",
      "path": "/contact",
      "icon": "perm_identity",
      "child_routes": null
   },
]
