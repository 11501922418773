import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const BlogReleaseItem = (props) => {
    const dispatch = useDispatch();

    const blogRelease = useSelector(state => state.blog_release.blogRelease);
    const setBlogRelease = (payload) => dispatch({type: 'SET_BLOG_RELEASE', payload});

    const handleSave = () => {
        let temp_blogRelease = [...blogRelease];
        let blogRelease_index = temp_blogRelease.indexOf(temp_blogRelease.filter(v => v.id === props.id)[0]);
        temp_blogRelease[blogRelease_index].is_saved = !temp_blogRelease[blogRelease_index].is_saved;

        setBlogRelease(temp_blogRelease);
    };

    return (
        <div className="item">
            <div className="latest-post-item">
                <div className="latest-post-item-inner">
                    <div className="latest-post-image">
                        <img src={props.thumbnail_data} className="img-fluid" alt="post-img" />
                    </div>
                    <div className="latest-post-content">
                        <div className="post-date">
                            <div className="post-date-inner">
                                {props.date.format('DD MMMM YYYY')}
                            </div>
                        </div>
                        <div className="latest-post-meta">
                            <ul>
                                <li>
                                    <i className="fa fa-folder-open" />
                                    <Link to="#" rel="category tag">{props.category.name}</Link>
                                </li>
                                <li>
                                    <img
                                        style={{
                                            height: 20,
                                            position: 'relative',
                                            top: -3,
                                            left: 147,
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleSave}
                                        src={props.is_saved ? require(`../../assets/images/saved.png`) : require(`../../assets/images/not-saved.png`)}
                                    />
                                </li>
                            </ul>
                        </div>
                        <h3 className="blog-title">
                            <Link to={`/blog-release/${props.id}`}>{props.title}</Link>
                        </h3>
                        <div className="latest-post-excerpt">
                            <p>{props.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogReleaseItem;
