import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {useSelector} from "react-redux";

const ProfileDetail = () => {
    const profile = useSelector(state => state.users.profile);

    return(
        <Row>
            <Col lg={12}>
                <div className="admin-profile">
                    <div className="admin-profile-image">
                        <img style={{width: 80, height: 80, objectFit: 'cover'}} className="img-fluid rounded-circle" src={profile.photo} alt="profile"/>
                    </div>
                    <div className="admin-profile-info">
                        <h6 className="mb-0">{profile.name}</h6>
                        <span className="text-dark">{profile.email}</span>
                    </div>
                    {profile.phone != '' ?
                        <div className="admin-profile-number ml-auto">
                            <strong className="h2"> <i className="fa fa-phone-square pr-2"></i>{profile.phone}</strong>
                        </div>
                        :
                        null
                    }
                </div>
            </Col>
        </Row>
    )
}
export default ProfileDetail;
