import Get from "../Get";
import {filterQueryString} from "../../helpers";
import Post from "../Post";
import Download from "../Download";

const EventPii = {
    get: (data = null) => Get(`event?${data ? filterQueryString(data) : ''}`),
    getOne: (id) => Get(`event/${id}`),
    buy: id => Post(`event/${id}/buy`),
    user: () => Get(`event/user`),
    downloadCertificate: (user_event_id, event_title) => Download(`event/download/${user_event_id}`, `Sertifikat Event ${event_title}.pdf`),
};

export default EventPii;
