export const RootPath = 'https://admin-pii.omindtech.id/api';
// export const RootPath = 'http://localhost:8000/api';
export const Headers = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,PUT,DELETE,OPTIONS",
        'Access-Control-Allow-Headers': "X-Requested-With, Content-Type, X-Token-Auth, Authorization",
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
};
