import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import EventItem from "../../component/event/item";
import {useSelector} from "react-redux";
import moment from 'moment';
import {Button, Col, Row} from "reactstrap";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const PiiEventSlider  = (props) => {
    const piiEvents = useSelector(state => state.pii_event.piiEvents);
    const [piiEventSliderData, setPiiEventSliderData] = useState([...piiEvents]);
    const [tab, setTab] = useState(1);

    const handleFilter = (dataTab) => {
        setTab(dataTab);

        let temp_piiEvent = [...piiEvents];
        temp_piiEvent = temp_piiEvent.filter(v => dataTab === 1 ? v.started_at.isSameOrAfter(moment()) : v.finished_at.isBefore(moment()));
        setPiiEventSliderData(temp_piiEvent);
    };

    useEffect(() => {
       setPiiEventSliderData(piiEvents);
    }, [piiEvents]);

    useEffect(() => {
        if(props.isOrganizer)
            setPiiEventSliderData([...piiEvents].filter(v => v.started_at.isSameOrAfter(moment())));
    }, []);

    return (
        <div className="latest-post-wrapper latest-post-type-carousel latest-post-style-2 latest-post-without-intro">
            {
                props.isOrganizer &&
                    <>
                        <Button onClick={() => handleFilter(1)} outline={tab !== 1} color={'primary'} className={'my-4 mr-4'}>Event baru</Button>
                        <Button onClick={() => handleFilter(2)} outline={tab !== 2} color={'primary'} className={'my-4'}>Event lama</Button>
                    </>
            }
            {
                (piiEventSliderData.length < 4 && piiEventSliderData.length > 0) ?
                    <Row>
                        {
                            piiEventSliderData.map(v => (
                                <Col md={3}>
                                    <EventItem {...v} />
                                </Col>
                            ))
                        }
                    </Row> :
                    <Slider {...settings} className="slider-spacing-10 slider-arrow-hover">
                        {
                            piiEventSliderData.map(v => {
                                return <EventItem {...v} />
                            })
                        }
                    </Slider>
            }
        </div>
    )
};

export default PiiEventSlider;


