import {getWithExpiry, setWithExpiry, setWithoutExpiry} from "../helpers";
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

export default (state = {
    isLoggedIn: getWithExpiry('user'),
    profile: getWithExpiry('user') ?? {},
    followings: [
        {
            id: 1,
            name: 'Jonat',
            photo: require('../assets/images/user.png')
        },
        {
            id: 2,
            name: 'Insinyur Barto',
            photo: require('../assets/images/logo.png')
        }
    ],
    piiEvents: [],
    likedPiiEvents: [],
}, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'SET_IS_LOGGED_IN':
            return {
              ...state,
              isLoggedIn: payload
            };
        case 'LOGIN':
            payload.data.user.photo = payload.data.user.photo_to_user;
            localStorage.setItem('token', payload.data.access_token);
            setWithExpiry('user', payload.data.user, payload.data.expires_in * 60 * 1000);

            return {
                ...state,
                profile: payload
            };
        case 'SET_PROFILE':
            setWithoutExpiry('user', payload);

            return {
                ...state,
                profile: payload
            };
        case 'SET_FOLLOWINGS':
            return {
                ...state,
                followings: payload
            };
        case 'SET_USER_PII_EVENTS':
            return {
                ...state,
                piiEvents: payload.map(v => {
                    v.started_at = moment(v.started_at);
                    v.finished_at = moment(v.finished_at);
                    v.date = moment(v.date);

                    return v
                })
            };
        case 'SET_USER_LIKED_PII_EVENTS':
            return {
                ...state,
                likedPiiEvents: payload
            };
        default:
          return {
              ...state
          };
    }
};
