import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PageTitle from '../../widgets/PageTitle';
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import BlogReleaseItem from "./item";
import API from "../../network";

const BlogRelease = () => {
    const dispatch = useDispatch();

    const categories = useSelector(state => state.blog_release.categories);
    const setCategories = (payload) => dispatch({type: 'SET_BLOG_RELEASE_CATEGORIES', payload});

    const blogRelease = useSelector(state => state.blog_release.blogRelease);
    const setBlogs = (payload) => dispatch({type: 'SET_BLOG_RELEASE', payload});

    const [filteredBlogRelease, setFilteredBlogRelease] = useState(blogRelease);

    const changeCategory = (value) => {
        setFilteredBlogRelease([]);
        let temp_blogRelease = [...blogRelease];

        if(value !== null)
            temp_blogRelease = temp_blogRelease.filter(v => v.category.id === value);

        setFilteredBlogRelease(temp_blogRelease);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getCategories();
        getBlogs();
    }, []);

    const getCategories = async () => {
        try{
            let res = await API.blog.categories();
            setCategories(res.categories);
        } catch (e) {
            console.log(e);
        }
    };

    const getBlogs = async () => {
        try{
            let res = await API.blog.getAll();
            console.log(res);
            setBlogs(res.blogs);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="site-content">
            <div className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
                <Container>
                    <Row className="intro-title align-items-center intro-section-center">
                        <PageTitle title="Blog & Release"/>
                    </Row>
                </Container>
            </div>
            <div className="content-wrapper">
                <div className="section-wrapper section-pt pb-0 pb-lg-4">
                    <Container>
                        <Row>
                            <Col sm={4} md={4} className={'mb-4'}>
                                <Select
                                    options={[
                                        {value: null, label: 'Semua kategori'},
                                        ...categories
                                    ]}
                                    placeholder={'Pilih kategori...'}
                                    onChange={({value}) => changeCategory(value)}
                                />
                            </Col>
                            <Col sm={12} md={12} className={'mb-4'}>
                                <div className="latest-post-wrapper latest-post-type-carousel latest-post-style-2 latest-post-without-intro">
                                    <Row>
                                    {filteredBlogRelease.map(v => (
                                        <Col md={3} className={'mb-4'}>
                                            <BlogReleaseItem {...v} />
                                        </Col>
                                    ))}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}
export default BlogRelease;
