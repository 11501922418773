import Post from "../Post";
import Get from "../Get";

const User = {
    register: (data) => Post(`register`, data),
    login: (data) => Post(`login`, data),
    logout: () => Post(`logout`),
    update_profile: (data) => Post(`user`, data),
    bidang: () => Get(`bidang`),
    daerah: () => Get(`locations`),
    banner: () => Get(`banners`),
};

export default User;
