import React, {useState, useEffect} from 'react';
import {Button, Col, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import PageTitle from "../../widgets/PageTitle";
import {useHistory} from "react-router";
import PiiEventSlider from "./slider";
import Loader from "react-loader-spinner";
import StarRatingComponent from 'react-star-rating-component';
import moment from 'moment';
import 'moment/locale/id';
import {useDispatch, useSelector} from "react-redux";
import API from "../../network";
moment.locale('id');

const CheckOutPii = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const userPiiEvents = useSelector(state => state.users.piiEvents);
    const setUserPiiEvents = (data) => dispatch({type: 'SET_USER_PII_EVENTS', payload: data});

    const [id, setId] = useState(parseInt(props.match.params.id));
    const [piiEvent, setPiiEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleCheckout = () => {
        let temp_userPiiEvents = [...userPiiEvents];
        let temp_piiEvent = {...piiEvent};
        temp_piiEvent.bidang = [piiEvent.bidang[0]];

        temp_userPiiEvents.unshift(temp_piiEvent);

        console.log(temp_userPiiEvents);

        API.event.buy(id);

        setUserPiiEvents(temp_userPiiEvents);
        history.push('/user-events');
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        getEventPii();
    }, []);

    const getEventPii = async () => {
        let {eventPii} = await API.event.getOne(id);
        eventPii.started_at = moment(eventPii.started_at);
        eventPii.finished_at = moment(eventPii.finished_at);
        eventPii.date = moment(eventPii.date);
        setPiiEvent(eventPii);
        setIsLoading(false);
    };

    return (
        isLoading ?
        <div id="preloader">
            <Loader
                type="Puff"
                color="#EB6C24"
                height="100"
                width="100"
            />
        </div> :
        <div className="site-content">
            <div className="content-wrapper">
                <div className="section-wrapper single-post section-ptb pb-5">
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={12} xl={12}>
                                <article className="post format-standard">
                                    <div className="entry-header-section">
                                        <div className="entry-meta-date">
                                            <h4 className={'text-center'} style={{color: '#EB6C24', fontWeight: 'bold'}}>Informasi Tiket</h4>
                                        </div>
                                            <h3 className={'text-center'} style={{fontWeight: 'bold'}}>{piiEvent.title}</h3>
                                            <p className={'text-center'}>{piiEvent.bidang_data[0].name}</p>
                                    </div>

                                    <div className="entry-content">
                                        <Row>
                                            <Col md={12} className={'mb-3'}>
                                                <h4>{piiEvent.title}</h4>
                                                <p>Penjualan berakhir dalam satu jam</p>
                                            </Col>
                                            <Col md={12} className={'mb-3'}>
                                                <h4>Ringkasan pesanan</h4>
                                                <Row>
                                                    <Col md={6}>
                                                        <p>1x {piiEvent.title}</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p style={{ fontWeight: 'bold', color: '#04A981', textAlign: 'right'}}>FREE</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12} className={'mb-3'}>
                                                <Row>
                                                    <Col md={6}>
                                                        <h6 style={{fontWeight: 'bold'}}>Total</h6>
                                                    </Col>
                                                    <Col md={6}>
                                                        <p style={{ fontWeight: 'bold', color: '#04A981', textAlign: 'right'}}>FREE</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12} className={'mb-3 text-center'}>
                                                <Button onClick={handleCheckout} color={'primary'} style={{width: 230}}>Checkout</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </article>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default CheckOutPii;
