import EventPii from "./event_pii";
import Follow from "./follow";
import User from "./user";
import Blog from "./blog";

const API = {
  event: EventPii,
  blog: Blog,
  follow: Follow,
  user: User,
};

export default API;
