import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Sitebar from "./sitebar";
import ProfileDetail from "./ProfileDetail";
import {useDispatch, useSelector} from "react-redux";
import EventItem from "../event/item";
import moment from 'moment';
import API from '../../network';

const UserEvents = () => {
  const dispatch = useDispatch();

  const piiEvents = useSelector(state => state.users.piiEvents);
  const likedPiiEvents = useSelector(state => state.users.likedPiiEvents);

  const setPiiEvents = payload => dispatch({type: 'SET_USER_PII_EVENTS', payload});

  const [dataEvent, setDataEvent] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
      API.event.user()
        .then(({event_piis}) => {
            console.log(event_piis);
            setPiiEvents(event_piis);
            setDataEvent(event_piis.filter(v => moment(`${v.date.format('YYYY-MM-DD')} ${v.started_at.format('HH:mm')}`).isSameOrAfter(moment())));
        })
        .catch(e => {
            console.log(e);
        })
  }, []);

  useEffect(() => {
      switch (tab) {
          case 0: setDataEvent(piiEvents.filter(v => moment(`${v.date.format('YYYY-MM-DD')} ${v.started_at.format('HH:mm')}`).isSameOrAfter(moment()))); break;
          case 1: setDataEvent(likedPiiEvents.filter(v => v.is_liked)); break;
          case 2: setDataEvent(piiEvents.filter(v => moment(`${v.date.format('YYYY-MM-DD')} ${v.finished_at.format('HH:mm')}`).isBefore(moment()))); break;
      }
  }, [tab]);

  return (
    <div className="section-ptb">
        <Container>
            <ProfileDetail />
            <Row>
                <Sitebar />
                <Col lg={9} className="mt-4 mt-lg-0">
                    <Row>
                        <Col lg={12} className={'mb-4'}>
                            <Button outline={tab !== 0} onClick={() => setTab(0)} color={'primary'} className={'mr-3'}>Tiket</Button>
                            <Button outline={tab !== 1} onClick={() => setTab(1)} color={'primary'} className={'mr-3'}>Disukai</Button>
                            <Button outline={tab !== 2} onClick={() => setTab(2)} color={'primary'} className={'mr-3'}>Riwayat Event</Button>
                        </Col>
                        <Col lg={12}>
                            <div className="latest-post-wrapper latest-post-type-carousel latest-post-style-2 latest-post-without-intro">
                                <Row>
                                    {
                                        dataEvent.map(v => (
                                            <Col sm={4} className={'mb-3 entry-content'}>
                                                <EventItem {...v} isUser={tab !== 1} isLiked={tab === 1} />
                                            </Col>
                                        ))
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
   )
}
export default UserEvents;
